import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const loader = document.querySelector(".loading");

// if you want to show the loader when React loads data again
const showLoader = () => loader?.classList.remove("loader--hide");
const hideLoader = () => loader?.classList.add("loader--hide");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App
        title="Boardsales Website"
        hideLoader={hideLoader}
        showLoader={showLoader}
      />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
