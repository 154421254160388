/// PUBLIC
//import LandingPage from "./Pages/Public/Landing";
//import Registration from "./Pages/Public/Registration";
import Login from "./Pages/Public/Login";
import ForgotPassword from "./Pages/Public/ForgotPassword";
import VerifyUser from "./Pages/Public/VerifyUser";
import SessionExpired from "./Pages/Public/SessionExpired";
// import LockScreen from "./Pages/Public/LockScreen";
import Error400 from "./Pages/Public/Error400";
import Error403 from "./Pages/Public/Error403";
import Error404 from "./Pages/Public/Error404";
import Error500 from "./Pages/Public/Error500";
import Error503 from "./Pages/Public/Error503";
import ContactSupport from "./Pages/Public/ContactSupport";
import Landing from "./Pages/Public/landing-page/Landing";

// /// DASHBOARD
//import Dashboard from "./Pages/App/Dashboard/Dashboard";

// /// Admin
import Admin from "./Pages/App/Admin/Admin";

// /// USER ACCOUNT
// import UserProfile from "./Pages/App/User/UserProfile";
//import ChangePassword from "./Pages/App/User/ChangePassword";
import ResetPassword from "Pages/Public/ResetPassword";
import Dashboard from "Pages/App/Dashboard/dashboard";
import Users from "Pages/App/Users/users";
import Products from "Pages/App/Products/products";
import Sales from "Pages/App/Sales/sales";
import Payments from "Pages/App/Payments/payments";
import { PrivacyPolicy } from "Pages/Public/privacy-policy/privacy-policy";
import ConfirmDeleteUser from "Pages/Public/confirm-delete/confirm-delete-user";

export const routes = [
  /// Dashboard
  // { url: "app/dashboard", component: Dashboard, roles: ["Admin"] },
  { url: "app/users", component: Users, roles: ["Admin"] },
  { url: "app/products", component: Products, roles: ["Admin"] },
  { url: "app/sales", component: Sales, roles: ["Admin"] },
  { url: "app/payments", component: Payments, roles: ["Admin"] },

  /// My Budget
  //{ url: "app/my-budget", component: MyBudget },
  /// Budget Overview
  //{ url: "app/budget-overview", component: BudgetOverview, roles: ["Admin", "Manager"] },
  /// BrokerBudgets
  //{ url: "app/broker-budgets", component: BrokerBudgets, roles: ["Admin", "Manager"] },

  /// Admin
  {
    url: "app/admin",
    component: Admin,
    roles: ["Admin"],
    // items: [
    //   {
    //     url: "app/admin/users",
    //     component: Users,
    //     roles: ["Admin"],
    //   },
    //   {
    //     url: "app/admin/budget-periods",
    //     component: BudgetPeriods,
    //     roles: ["Admin"],
    //   },
    //   {
    //     url: "app/admin/expenses-and-cashflow",
    //     component: ExpensesAndCashflow,
    //     roles: ["Admin"],
    //   },
    // ],
  },

  /// USER Account
  //{ url: "app/user/change-password", component: ChangePassword },

  /// GENERAL PROTECTED PAGES for
  { url: "app/session-expired", component: SessionExpired },
  { url: "app/error-400", component: Error400 },
  { url: "app/error-403", component: Error403 },
  { url: "app/error-404", component: Error404 },
  { url: "app/error-500", component: Error500 },
  { url: "app/error-503", component: Error503 },
  { url: "app/privacy-policy", component: PrivacyPolicy },
  { url: "app/confirm-delete-user", component: ConfirmDeleteUser },

  // PUBLIC ROUTES
  { url: "login", auth: "public", component: Login },
  // { url: "register", auth: "public", component: Registration },
  // { url: "lock", auth: "public", component: LockScreen },
  { url: "forgot-password", auth: "public", component: ForgotPassword },
  { url: "session-expired", auth: "public", component: SessionExpired },
  { url: "error-400", auth: "public", component: Error400 },
  { url: "error-403", auth: "public", component: Error403 },
  { url: "error-404", auth: "public", component: Error404 },
  { url: "error-500", auth: "public", component: Error500 },
  { url: "error-503", auth: "public", component: Error503 },
  { url: "privacy-policy", auth: "public", component: PrivacyPolicy },
  { url: "confirm-delete-user", auth: "public", component: ConfirmDeleteUser },
  {
    url: "reset-password/:id/:token",
    auth: "public",
    component: ResetPassword,
  },
  { url: "verify-user/:token", auth: "public", component: VerifyUser },
  { url: "contact-support", auth: "public", component: ContactSupport },
  { url: "/login", auth: "public", component: Login },
  { url: "/landing", auth: "public", component: Landing },
  { url: "/", auth: "public", component: Landing },
];
