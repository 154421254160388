import { useNavigate } from "react-router-dom";
import AuthService from "Services/AuthService";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function pascalCaseToTitle(text) {
  const result = text.replace(/([A-Z])/g, " $1");

  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function snakeCaseToTitle(text) {
  let title = text.replace(/(\$)/g, "/").split("_");
  for (let i = 0; i < title.length; i++) {
    title[i] = title[i][0].toUpperCase() + title[i].slice(1);
  }

  return title.join(" ");
}

// export async function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

export async function getBase64(file) {
  let reader = new FileReader();
  await reader.readAsDataURL(file);
  reader.onload = await function () {
    return reader.result;
  };
  reader.onerror = await function (error) {
    console.log("Error: ", error);
    return null;
  };
}

export function getBase64FromFile(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

export const getMonthName = (MonthNo) => {
  //console.info("getMonthName", MonthNo);
  switch (+MonthNo) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "Mai";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Okt";
    case 11:
      return "Nov";
    case 12:
      return "Des";

    default:
      return "";
  }
};

export const formatCurrency = (locale, number) => {
  return new Intl.NumberFormat(locale).format(number ? number : 0);
};

export const dateFormat = (inputDate, format) => {
  //parse the input date
  const date = new Date(inputDate);

  //extract the parts of the date
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  //replace the month
  format = format.replace("MM", month.toString().padStart(2, "0"));

  //replace the year
  if (format.indexOf("yyyy") > -1) {
    format = format.replace("yyyy", year.toString());
  } else if (format.indexOf("yy") > -1) {
    format = format.replace("yy", year.toString().substr(2, 2));
  }

  //replace the day
  format = format.replace("dd", day.toString().padStart(2, "0"));

  return format;
};

export function ValidateRole(validRoles) {
  if (
    validRoles.filter((vr) => vr === AuthService.getUserRole()).length === 0
  ) {
    // const navigate = useNavigate();
    // navigate("/login");
  }
}
