import BaseApiService from "Services/BaseApiService";
import { Modal, Row, Col, Button, Space, Checkbox } from "antd";
import CustomDataGrid from "components/DevEx/CustomDataGrid";
import { useEffect, useState } from "react";
import environment from "Environment";
import { trackPromise } from "react-promise-tracker";
import { Tabs } from "antd/lib";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { alertify, notify } from "Services/ToastService";
import UserBoardForm from "../Products/UserBoardForm";
import UserGearForm from "../Products/UserGearForm";
const { RangePicker } = DatePicker;

var dateFormat = "DD/MM/YYYY";

const Sales = () => {
  const [boards, setBoards] = useState([]);
  const [gears, setGears] = useState([]);
  const [showFormBoard, setShowFormBoard] = useState(false);
  const [showFormGear, setShowFormGear] = useState(false);

  const [userBoard, setUserBoard] = useState();
  const [userGear, setUserGear] = useState();

  const [locations, setLocations] = useState();
  const [boardTypes, setBoardTypes] = useState();
  const [finSystems, setFinSystems] = useState();
  const [finSetups, setFinSetups] = useState();
  const [shapers, setShapers] = useState();

  const [dateRange, setDateRange] = useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);
  const [previousDateRange, setPrevioustDateRange] = useState();

  const [allTimesDateRange, setAllTimesDateRange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchLookups();
      await fetchBoards();
    };
    fetchData();
  }, []);

  const fetchBoards = async () => {
    console.log("Date Range", dateRange);
    if (!dateRange[0] || !dateRange[1]) {
      notify("Please select correct date range for the report");
      return;
    }
    let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
    let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.GET(`/Admin/sales/B/${sDate}/${eDate}`)
      );
      notify(message, status);
      console.log("Boards", data);
      setBoards(data);
    } catch (error) {
      console.error("Failed to fetch boards:", error);
    }
  };

  const fetchGears = async () => {
    console.log("Date Range", dateRange);
    if (!dateRange[0] || !dateRange[1]) {
      notify("Please select correct date range for the report");
      return;
    }
    let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
    let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.GET(`/Admin/sales/G/${sDate}/${eDate}`)
      );
      notify(message, status);
      console.log("Gears", data);
      setGears(data);
    } catch (error) {
      console.error("Failed to fetch gears:", error);
    }
  };

  const fetchLookups = async () => {
    const { data, message, status } = await trackPromise(
      BaseApiService.GET("/AP_Lookup")
    );
    setLocations(data.locations);
    setBoardTypes(data.boardTypes);
    setFinSystems(data.finSystems);
    setFinSetups(data.finSetups);
    setShapers(data.shapers);
  };

  const columns_boards = [
    {
      caption: "Board Id",
      dataField: "boardId",
      dataType: "number",
    },
    {
      caption: "Seller Id",
      dataField: "sellerId",
      dataType: "number",
    },
    {
      caption: "Seller",
      dataField: "seller",
      dataType: "string",
    },

    {
      caption: "Buyer Id",
      dataField: "buyerId",
      dataType: "number",
    },
    {
      caption: "Buyer",
      dataField: "buyer",
      dataType: "string",
    },

    {
      caption: "Board Title",
      dataField: "title",
      dataType: "string",
    },
    {
      caption: "Condition",
      dataField: "condition",
      cellRender: (item) => {
        let row = item.row.data;
        return row.condition === 1 ? "NEW" : "USED";
      },
    },

    {
      caption: "Price",
      dataField: "price",
      valueFormat: "currency",
      displayFormat: "$ {1}",
    },
    {
      caption: "Consider Swap",
      dataField: "considerSwap",
      dataType: "boolean",
    },

    {
      caption: "Date Added",
      dataField: "dateAdded",
      dataType: "date",
    },

    {
      caption: "Purchase Date",
      dataField: "purchaseDate",
      dataType: "date",
      format: "dd-MM-yyyy",
    },
    {
      caption: "Board Type",
      dataField: "boardType",
      dataType: "string",
    },
    {
      caption: "Location",
      dataField: "location",
      dataType: "string",
    },
  ];

  const columns_gears = [
    {
      caption: "Board Id",
      dataField: "boardId",
      dataType: "number",
    },
    {
      caption: "Seller Id",
      dataField: "sellerId",
      dataType: "number",
    },
    {
      caption: "Seller",
      dataField: "seller",
      dataType: "string",
    },

    {
      caption: "Buyer Id",
      dataField: "buyerId",
      dataType: "number",
    },
    {
      caption: "Buyer",
      dataField: "buyer",
      dataType: "string",
    },

    {
      caption: "Gear Title",
      dataField: "title",
      dataType: "string",
    },
    {
      caption: "Condition",
      dataField: "condition",
      cellRender: (item) => {
        let row = item.row.data;
        return row.condition === 1 ? "NEW" : "USED";
      },
    },

    {
      caption: "Price",
      dataField: "Price",
      dataType: "currency",
    },
    {
      caption: "Consider Swap",
      dataField: "considerSwap",
      dataType: "boolean",
    },

    {
      caption: "Date Added",
      dataField: "dateAdded",
      dataType: "date",
      format: "dd-MM-yyyy",
    },

    {
      caption: "Purchase Date",
      dataField: "purchaseDate",
      dataType: "date",
      format: "dd-MM-yyyy",
    },
  ];

  const handleEditBoard = (usrBoard) => {
    setUserBoard(usrBoard);
    setShowFormBoard(true);
  };

  const handleEditGear = (usrGear) => {
    setUserGear(usrGear);
    setShowFormGear(true);
  };

  const handleSaveBoard = async (updatedUserBoard) => {
    const { data, status, message } = await trackPromise(
      BaseApiService(`/Admin/UpdateUserBoardByAdmin`, updatedUserBoard)
    );

    alertify(message, status);

    setShowFormBoard(false);
    fetchBoards();
  };

  const handleSaveGear = async (updatedUserGear) => {
    const { data, status, message } = await trackPromise(
      BaseApiService(`/Admin/UpdateUserGearByAdmin`, updatedUserGear)
    );

    alertify(message, status);

    setShowFormBoard(false);
    fetchBoards();
  };

  const handleDeleteBoard = async (obj) => {
    const { status, message } = await trackPromise(
      BaseApiService.POST(`/Admin/DeleteBoardByAdmin/${obj.id}`)
    );

    alertify(message, status);

    if (status) fetchBoards();
  };

  const handleDeleteGear = async (obj) => {
    const { status, message } = await trackPromise(
      BaseApiService.POST(`/Admin/DeleteGearByAdmin/${obj.id}`)
    );

    alertify(message, status);

    if (status) fetchGears();
  };

  const handleCancel = () => {
    setShowFormBoard(false);
    setShowFormGear(false);
  };

  return (
    <>
      <Row>
        <Col className="mr-auto">
          <h2> Sales </h2>
        </Col>
        <Col className="mb-2">
          <Space>
            <Checkbox
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  setPrevioustDateRange([...dateRange]);
                  setDateRange([dayjs("2024-01-01"), dayjs()]);
                } else {
                  setDateRange([...previousDateRange]);
                }
                setAllTimesDateRange(checked);
              }}
            />{" "}
            <span className="text-grey">All times sales!</span> <b>OR</b>
            <label className="text-small">Sold between: </label>
            <RangePicker
              disabled={allTimesDateRange}
              format={dateFormat}
              allowClear={true}
              allowEmpty={[true, true]}
              onCalendarChange={(e) => {
                setDateRange(e);
              }}
              value={dateRange}
              defaultValue={dateRange}
              //defaultValue={[moment(dateRange.sDate), moment(dateRange.eDate)]}
            />
            <Button
              onClick={async () => {
                await fetchBoards();
                await fetchGears();
              }}
            >
              Search
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <br />
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={<></>}
        className="flex"
        style={{ width: "100%" }}
        onLoad={fetchBoards}
        onChange={async (tabKey) => {
          switch (tabKey) {
            case "boards":
              fetchBoards();
              break;
            case "gears":
              fetchGears();
              break;
            default:
              break;
          }
        }}
        items={[
          {
            label: "Surf Boards",
            key: "boards",
            children: (
              <>
                <CustomDataGrid
                  data={boards}
                  columns={columns_boards}
                  ENTITY={"Sold Surfboard"}
                  ENTITY_PLURAL={"Sold Surfboards"}
                  editHandler={handleEditBoard}
                  deleteHandler={handleDeleteBoard}
                  handleCancel={handleCancel}
                  canView={true}
                  canEdit={false}
                  canDelete={false}
                  doPaging={false}
                  canSelect={false}
                />
                <Modal
                  width={768}
                  open={showFormBoard}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {userBoard && (
                    <UserBoardForm
                      data={userBoard}
                      onSave={handleSaveBoard}
                      onCancel={handleCancel}
                    />
                  )}
                </Modal>
              </>
            ),
          },
          {
            label: "Gears",
            key: "gears",
            children: (
              <>
                <CustomDataGrid
                  data={gears}
                  columns={columns_gears}
                  ENTITY={"Sold Gear"}
                  ENTITY_PLURAL={"Sold Gears"}
                  editHandler={handleEditGear}
                  deleteHandler={handleDeleteGear}
                  handleCancel={handleCancel}
                  canView={true}
                  canEdit={false}
                  canDelete={false}
                  doPaging={false}
                  canSelect={false}
                />
                <Modal
                  width={768}
                  open={showFormGear}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {userBoard && (
                    <UserGearForm
                      data={userGear}
                      onSave={handleSaveGear}
                      onCancel={handleCancel}
                    />
                  )}
                </Modal>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default Sales;
