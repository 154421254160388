const environment = {
  NAME: "DEVELOPMENT",
  APPLICATION_NAME: "DEVELOPMENT",
  VERSION: "2.0",
  ADMIN_USERNAME: "@admin007",
  ADMIN_PASSWORD: "DannyMay82!",
  ENVIRONMENT_CLASS: "bg-primary text-light",
  API: {
    BASE_URL: "http://localhost:5243/admin-api", //"http://api-stg.boardsales.com/admin-api",
    USERNAME: "khurram",
    PASSWORD: "123",
    API_TIMEOUT: 500000,
    VERSION: "",
  },
};

if (process.env.REACT_APP_ENV === "staging") {
  environment.NAME = "STAGING";
  environment.ENVIRONMENT_CLASS = "bg-warning text-dark";
  environment.API.BASE_URL = "https://api.boardsales.com/admin-api";
  environment.API.VERSION = "";
}

if (process.env.REACT_APP_ENV === "production") {
  environment.NAME = "PRODUCTION";
  environment.ENVIRONMENT_CLASS = "bg-success";
  environment.API.BASE_URL = "https://api.boardsales.com/admin-api";
  environment.API.VERSION = "";
}

export default environment;
