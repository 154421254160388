import BaseApiService from "Services/BaseApiService";
import { Modal, Row, Col, Button, Space, Checkbox } from "antd";
import CustomDataGrid from "components/DevEx/CustomDataGrid";
import { useEffect, useState } from "react";
import environment from "Environment";
import { trackPromise } from "react-promise-tracker";
import { Tabs } from "antd/lib";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { alertify, notify } from "Services/ToastService";
import UserBoardForm from "./UserBoardForm";
import UserGearForm from "./UserGearForm";
const { RangePicker } = DatePicker;

var dateFormat = "DD/MM/YYYY";

const Products = () => {
  const [boards, setBoards] = useState([]);
  const [gears, setGears] = useState([]);
  const [showFormBoard, setShowFormBoard] = useState(false);
  const [showFormGear, setShowFormGear] = useState(false);

  const [userBoard, setUserBoard] = useState();
  const [userGear, setUserGear] = useState();

  const [locations, setLocations] = useState();
  const [boardTypes, setBoardTypes] = useState();
  const [finSystems, setFinSystems] = useState();
  const [finSetups, setFinSetups] = useState();
  const [shapers, setShapers] = useState();

  const [dateRange, setDateRange] = useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);
  const [previousDateRange, setPrevioustDateRange] = useState();

  const [allTimesDateRange, setAllTimesDateRange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchLookups();
      await fetchBoards();
    };
    fetchData();
  }, []);

  const fetchBoards = async () => {
    console.log("Date Range", dateRange);
    if (!dateRange[0] || !dateRange[1]) {
      notify("Please select correct date range for the report");
      return;
    }
    let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
    let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.GET(`/Admin/products/B/${sDate}/${eDate}`)
      );
      notify(message, status);
      console.log("Boards", data);
      setBoards(data);
    } catch (error) {
      console.error("Failed to fetch boards:", error);
    }
  };

  const fetchGears = async () => {
    console.log("Date Range", dateRange);
    if (!dateRange[0] || !dateRange[1]) {
      notify("Please select correct date range for the report");
      return;
    }
    let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
    let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.GET(`/Admin/products/G/${sDate}/${eDate}`)
      );
      notify(message, status);
      console.log("Gears", data);
      setGears(data);
    } catch (error) {
      console.error("Failed to fetch gears:", error);
    }
  };

  const fetchLookups = async () => {
    const { data, message, status } = await trackPromise(
      BaseApiService.GET("/AP_Lookup")
    );

    setLocations(data.locations);
    setBoardTypes(data.boardTypes);
    setFinSystems(data.finSystems);
    setFinSetups(data.finSetups);
    setShapers(data.shapers);
  };

  const columns_boards = [
    {
      caption: "Id",
      dataField: "id",
      dataType: "number",
    },
    {
      caption: "User Id",
      dataField: "userId",
      dataType: "number",
    },
    {
      caption: "User",
      dataField: "username",
      dataType: "string",
    },
    {
      caption: "Title",
      dataField: "title",
      dataType: "string",
    },
    {
      caption: "Condition",
      dataField: "conditionName",
    },
    {
      caption: "Location",
      dataField: "locationName",
    },
    {
      caption: "Distance",
      dataField: "distance",
    },
    {
      caption: "Board Type",
      dataField: "boardTypeName",
    },
    {
      caption: "Board Shapers",
      dataField: "boardShaperName",
    },
    {
      caption: "Fin System",
      dataField: "finSystemName",
    },
    {
      caption: "Fin Setup",
      dataField: "finSetupName",
    },
    {
      caption: "Surf Craft Type",
      dataField: "surfCraftType",
    },
    {
      caption: "Surf Craft Weight",
      dataField: "surfCraftWeight",
    },
    {
      caption: "Length",
      dataField: "length",
    },
    {
      caption: "Width",
      dataField: "width",
    },
    {
      caption: "Thickness",
      dataField: "thickness",
    },
    {
      caption: "Volume",
      dataField: "volume",
    },
    {
      caption: "Description",
      dataField: "description",
      dataType: "string",
    },
    {
      caption: "Price",
      dataField: "price",
    },
    {
      caption: "Consider Swap",
      dataField: "considerSwap",
      dataType: "boolean",
    },
    {
      caption: "Is Featured",
      dataField: "isFeatured",
      dataType: "boolean",
    },
    {
      caption: "Team Board",
      dataField: "teamBoard",
      dataType: "boolean",
    },
    {
      caption: "Vintage",
      dataField: "vintage",
      dataType: "boolean",
    },
    {
      caption: "Date Added",
      dataField: "dateAdded",
      dataType: "date",
      format: "dd-MM-yyyy",
    },
    // {
    //   caption: "Is Posted",
    //   dataField: "isPosted",
    //   dataType: "boolean",
    // },
    {
      caption: "Is Sold",
      dataField: "isSold",
      dataType: "boolean",
    },
    {
      caption: "Active",
      dataField: "active",
      dataType: "boolean",
    },
    {
      caption: "Purchase Date",
      dataField: "purchaseDate",
      dataType: "date",
      format: "dd-MM-yyyy",
    },
    {
      caption: "Buyer",
      dataField: "buyer",
      dataType: "string",
    },
    {
      caption: "Length CM",
      dataField: "lengthInCentimeter",
      dataType: "number",
    },

    {
      caption: "Images Path",
      dataField: "imagesPath",
      dataType: "string",
    },
  ];

  const columns_gears = [
    {
      caption: "Id",
      dataField: "id",
      dataType: "number",
    },
    {
      caption: "User Id",
      dataField: "userId",
      dataType: "number",
    },
    {
      caption: "User",
      dataField: "username",
      dataType: "string",
    },
    {
      caption: "Title",
      dataField: "title",
      dataType: "string",
    },
    {
      caption: "Condition",
      dataField: "conditionName",
    },

    // {
    //   caption: "Description",
    //   dataField: "description",
    //   dataType: "string",
    // },
    {
      caption: "Price",
      dataField: "price",
      dataType: "string",
    },

    // {
    //   caption: "Location",
    //   dataField: "location",
    //   cellRender: (item) => {
    //     let row = item.row.data;
    //     console.log("row", row);
    //     let d = locations?.filter((x) => x.id === row.location);
    //     return d ? d[0]?.name : null;
    //   },
    // },

    {
      caption: "Date Added",
      dataField: "dateAdded",
      dataType: "date",
      format: "dd-MM-yyyy",
    },
    {
      caption: "Is Posted",
      dataField: "isPosted",
      dataType: "boolean",
    },
    {
      caption: "Is Sold",
      dataField: "isSold",
      dataType: "boolean",
    },
    {
      caption: "Active",
      dataField: "active",
      dataType: "boolean",
    },
    {
      caption: "Archived",
      dataField: "isArchive",
      dataType: "boolean",
    },
    {
      caption: "Purchase Date",
      dataField: "purchaseDate",
      dataType: "date",
      format: "dd-MM-yyyy",
    },
    {
      caption: "buyer",
      dataField: "buyer",
    },

    {
      caption: "Images Path",
      dataField: "imagesPath",
      dataType: "string",
    },
  ];

  const handleEditBoard = (usrBoard) => {
    setUserBoard(usrBoard);
    setShowFormBoard(true);
  };

  const handleEditGear = (usrGear) => {
    setUserGear(usrGear);
    setShowFormGear(true);
  };

  const handleSaveBoard = async (updatedUserBoard) => {
    const { data, status, message } = await trackPromise(
      BaseApiService.POST(`/admin/UpdateUserBoardByAdmin`, updatedUserBoard)
    );

    alertify(message, status);

    setShowFormBoard(false);
    fetchBoards();
  };

  const handleSaveGear = async (updatedUserGear) => {
    console.log("gear to update", updatedUserGear);
    const { data, status, message } = await trackPromise(
      BaseApiService.POST(`/admin/UpdateUserGearByAdmin`, updatedUserGear)
    );

    alertify(message, status);

    setShowFormGear(false);
    fetchGears();
  };

  const handleDeleteBoard = async (obj) => {
    const { status, message } = await trackPromise(
      BaseApiService.POST(`/Admin/DeleteBoardByAdmin/${obj.id}`)
    );

    alertify(message, status);

    if (status) fetchBoards();
  };

  const handleDeleteGear = async (obj) => {
    const { status, message } = await trackPromise(
      BaseApiService.POST(`/Admin/DeleteGearByAdmin/${obj.id}`)
    );

    alertify(message, status);

    if (status) fetchGears();
  };

  const handleCancel = () => {
    setShowFormBoard(false);
    setShowFormGear(false);
  };

  return (
    <>
      <Row>
        <Col className="mr-auto">
          <h2> Products </h2>
        </Col>
        <Col className="mb-2">
          <Space>
            <Checkbox
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  setPrevioustDateRange([...dateRange]);
                  setDateRange([dayjs("2024-01-01"), dayjs()]);
                } else {
                  setDateRange([...previousDateRange]);
                }
                setAllTimesDateRange(checked);
              }}
            />{" "}
            <span className="text-grey">All times postings!</span> <b>OR</b>
            <label className="text-small">Posted between: </label>
            <RangePicker
              format={dateFormat}
              allowClear={true}
              allowEmpty={[true, true]}
              onCalendarChange={(e) => {
                setDateRange(e);
              }}
              //value={dateRange}
              defaultValue={dateRange}
              //defaultValue={[moment(dateRange.sDate), moment(dateRange.eDate)]}
            />
            <Button
              onClick={async () => {
                await fetchBoards();
                await fetchGears();
              }}
            >
              Search
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <br />
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={<></>}
        className="flex"
        style={{ width: "100%" }}
        onLoad={fetchBoards}
        onChange={async (tabKey) => {
          switch (tabKey) {
            case "boards":
              fetchBoards();
              break;
            case "gears":
              fetchGears();
              break;
            default:
              break;
          }
        }}
        items={[
          {
            label: "Surf Boards",
            key: "boards",
            children: (
              <>
                <CustomDataGrid
                  data={boards}
                  columns={columns_boards}
                  PK_FIELD={"id"}
                  ENTITY={"Surfboard"}
                  ENTITY_PLURAL={"Surfboards"}
                  editHandler={handleEditBoard}
                  deleteHandler={handleDeleteBoard}
                  handleCancel={handleCancel}
                  canView={true}
                  canEdit={true}
                  canDelete={true}
                  doPaging={false}
                  canSelect={false}
                />
                <Modal
                  width={768}
                  open={showFormBoard}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {userBoard && (
                    <UserBoardForm
                      data={userBoard}
                      onSave={handleSaveBoard}
                      onCancel={handleCancel}
                      lookups={{
                        locations,
                        boardTypes,
                        finSetups,
                        finSystems,
                        shapers,
                      }}
                    />
                  )}
                </Modal>
              </>
            ),
          },
          {
            label: "Gears",
            key: "gears",
            children: (
              <>
                <CustomDataGrid
                  data={gears}
                  columns={columns_gears}
                  PK_FIELD={"id"}
                  ENTITY={"Gear"}
                  ENTITY_PLURAL={"Gears"}
                  editHandler={handleEditGear}
                  deleteHandler={handleDeleteGear}
                  handleCancel={handleCancel}
                  canView={true}
                  canEdit={true}
                  canDelete={true}
                  doPaging={false}
                  canSelect={false}
                />
                <Modal
                  width={768}
                  open={showFormGear}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {userBoard && (
                    <UserGearForm
                      data={userGear}
                      onSave={handleSaveGear}
                      onCancel={handleCancel}
                      lookups={{
                        locations,
                        boardTypes,
                        finSetups,
                        finSystems,
                        shapers,
                      }}
                    />
                  )}
                </Modal>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default Products;
