import BaseApiService from "Services/BaseApiService";

class UserService extends BaseApiService {
  static async Register(user) {
    const url = `/users/register`;
    const postData = user;
    return await super.POST(url, postData);
  }

  static async Verify(verification_token) {
    const url = `/auth/verify/${verification_token}`;
    return await super.GET(url);
  }

  static async Login(user) {
    const url = `/auth/login`;
    const postData = user;
    return await super.POST(url, postData);
  }

  static async PasswordResetRequest(username) {
    const url = `/auth/PasswordResetRequest/${username}`;
    return await super.POST(url);
  }

  //it just verifies that the clicked link for resetting password is valid or not
  static async PasswordResetLinkValidation(userId, vCode) {
    const url = `/auth/PasswordResetLinkValidation/${userId}/${vCode}`;
    return await super.GET(url);
  }
  //it actually resets the password of user
  static async PasswordReset(userId, vCode, newPassword) {
    const url = `/auth/PasswordReset/${userId}/${vCode}/${newPassword}`;
    return await super.POST(url);
  }

  static async ChangePassword(userId, oldPassword, newPassword) {
    const url = `/auth/ChangePassword`;
    const postData = { userId, oldPassword, newPassword };
    return await super.POST(url, postData);
  }

  static async Principal_List() {
    const url = `/users/Principals`;
    return await super.GET(url);
  }

  //#region EdgeUser
  static async EdgeUser_List() {
    const url = `/users/StaffMembers`;
    return await super.GET(url);
  }

  static async EdgeUser_Add(edgeUser) {
    const url = `/users/SaveStaffMember`;
    const postData = edgeUser;
    return await super.POST(url, postData);
  }

  static async EdgeUser_Update(edgeUser) {
    const url = `/users/SaveStaffMember`;
    const postData = edgeUser;
    return await super.POST(url, postData);
  }

  static async EdgeUser_Delete(id) {
    const url = `/users/DeleteStaffMember/${id}`;
    return await super.DELETE(url);
  }

  //#endregion

  //#region BROKERS
  static async Brokers_List(periodYear = new Date().getFullYear()) {
    const url = `/users/Brokers/${periodYear}`;
    return await super.GET(url);
  }

  static async Brokers_List_By_Manager(managerID = 0, periodYear = new Date().getFullYear()) {
    const url = `/users/BrokersByManager/${managerID}/${periodYear}`;
    return await super.GET(url);
  }

  static async Managers_List() {
    const url = `/users/Managers`;
    return await super.GET(url);
  }

  static async GetBroker(id) {
    const url = `/users/StaffMember/${id}`;
    return await super.GET(url);
  }

  static async Broker_Save(broker) {
    const url = `/users/SaveUser`;
    return await super.POST(url, broker);
  }

  //#endregion

  //#region ADMIN
  static async AllStaffMembers() {
    const url = `/users/StaffMembers`;
    return await super.GET(url);
  }
  //#endregion
}

export default UserService;
