import { useState } from 'react';
import { Col, Row, Space } from "antd";

function ConfirmDeleteUser() {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');

    const handleOnSubmit = () => {
        if(email === '') {
            setMessage("Please enter a valid email address");
            return;
        }
        console.log("Delete user");
        setMessage("User deletion request submitted for " + email);
    }
    return (
<div style={{ marginLeft: "50px" }}>
      <Row gutter={[30, 30]} className="privacy-policy">
        <Col xs={22} md={22}>
        <h1>Boardsales Pty Ltd</h1>
    <p>ACN: 678 308 426</p>

    <h2>BOARDSALES.COM – DELETE CONFIRMATION</h2>

    <h3>Personal Information deletion request</h3>
    <p>Please enter the email address associated with your account</p>
    <p>Email: <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} /></p>
    <p><button onClick={handleOnSubmit}>Submit</button></p>
    <p>Once submitted, your account will be deleted within 30 days.</p>
    <p><b>{message}</b></p>
    <p>For any questions or notices, please contact us at:</p>
    <p>
        Boardsales Pty Ltd<br />
        Email: <a href="mailto:boardsalesglobal@gmail.com">boardsalesglobal@gmail.com</a><br />
        Last updated: 1st August 2024
    </p>
        </Col>
      </Row>
    </div>    
    );
}

export default ConfirmDeleteUser;