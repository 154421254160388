import { compareAsc, compareDesc, subDays, toDate } from "date-fns";
import { jwtDecode } from "jwt-decode";
import UserService from "./API/UserService";
import { ApiResponse } from "./BaseApiService";
import environment from "Environment";

const sign = require("jwt-encode");

export function dummyLogin(authUser) {
  logout();
  let SECRET = "SomeSecretKey";
  //authUser.password = "**********";
  authUser.role = "Admin";

  let status = false;
  let message = "No response from server";
  let localdev = authUser.username === environment.ADMIN_USERNAME;
  if (!localdev) {
    status = false;
    message = "Login Failed. Please try with correct credentials";
  } else {
    const jwt_token = sign(authUser, SECRET); // decode your token here
    if (jwt_token) {
      loginWithJwt(jwt_token);
      status = true;
      message = "Successfully logged in";
    }
  }
  return ApiResponse({
    data: { data: null, status, message },
    status,
    message,
  });
}

export function loginWithJwt(token) {
  setToken(token);
  //setCurrentUser(user);
  //http.SetTokenInAuthHeader(jwt);
}
const tokenKey = "token";
const userKey = "user";

export function setToken(token) {
  localStorage.setItem(tokenKey, JSON.stringify(token));
}
export function getToken() {
  return JSON.parse(localStorage.getItem(tokenKey));
}

export function getAuthToken() {
  let token_data = JSON.parse(localStorage.getItem(tokenKey));
  if (token_data) return token_data;
}
export function setCurrentUser(user) {
  localStorage.setItem(userKey, JSON.stringify(user));
}
export function getCurrentUser() {
  let token = JSON.parse(localStorage.getItem(tokenKey));
  if (token) return jwtDecode(token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(userKey);
  //http.RemoveTokenFromAuthHeader();
}

export function isLoggedIn() {
  try {
    //const token = getToken();
    const user = getCurrentUser();
    let result = false;
    // console.log(token, user);
    if (user && user.role) {
      if (
        [user.expiresAt, new Date()].sort(compareDesc)[0] === user.expiresAt
      ) {
        //if (compareAsc(new Date(user.expiresAt), new Date()) === 1) {
        //if token Expiry time is greater than current datetime
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  } catch (ex) {
    return false;
  }
}

export function getUserRole() {
  try {
    //const token = getToken();
    const user = getCurrentUser();
    if (user && user.role) return user.role;
  } catch (ex) {
    return "";
  }
}

export function isManager() {
  let role = getUserRole();
  if (role && (role === "Manager" || role === "Admin")) {
    return true;
  }
}

export function isBroker() {
  let role = getUserRole();
  if ((role && role === "Broker") || role === "Manager" || role === "Admin") {
    return true;
  }
}

export function isAdmin() {
  let role = getUserRole();
  if (role && role === "Admin") {
    return true;
  }
}

export function getUserModules() {
  const user = getCurrentUser();

  let modules = [];

  if (
    user &&
    user.role &&
    (user.role === "Broker" || user.role === "Manager" || user.role === "Admin")
  ) {
    //for brokers and managers
    modules.push("BMS Dashboard");
    modules.push("My Budget");
    // modules.push("Broker Budgets");
    // modules.push("Budget Overview");
  }

  if (user && user.role && (user.role === "Manager" || user.role === "Admin")) {
    //for managers and admin
    modules.push("Broker Budgets");
    modules.push("Budget Overview");
  }

  if (user && user.role && user.role === "Admin") {
    //for managers and admin
    modules.push("Admin");
  }

  return modules;
}

export default {
  dummyLogin,
  loginWithJwt,
  logout,
  setToken,
  getToken,
  getAuthToken,
  setCurrentUser,
  getCurrentUser,
  isLoggedIn,
  getUserRole,
  isManager,
  isBroker,
  isAdmin,
  getUserModules,
};
