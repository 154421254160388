import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "Services/AuthService";
// import { useForm, Controller } from "react-hook-form";
import { notify } from "Services/ToastService";
import { Card, Form, Input, Button, Row, Col, Result } from "antd";
import { trackPromise } from "react-promise-tracker";
import UserService from "Services/API/UserService";
import headerLogo from "assets/images/header-logo.png";

const { Meta } = Card;
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    AuthService.logout();
    return () => {
      //cleanup
    };
  }, []);

  const onFinish = async (values) => {
    //alert("form finished");
    //console.log("form", form);
    const username = values.username;
    const { status, message } = await trackPromise(
      UserService.PasswordResetRequest(username)
    );

    notify(message, status);

    if (status) {
      setCompleted(true);
    }
  };

  const onFinishFailed = () => {
    //alert("Please fill form with");
    notify("Please fill the form with your registered username", false);
  };
  return (
    <>
      <Row gutter={[5, 5]} align="middle" justify="center">
        <Col xl={8} xs={22}>
          <div
            className="text-center ml-auto mr-auto p-4 flex"
            style={{ alignItems: "center" }}
          >
            <h2
              className="main-title mr-auto"
              style={{ display: "inline-block" }}
            >
              Forgot your password?
            </h2>
            <img className="ml-auto" src={headerLogo} width={250} />
          </div>
        </Col>
      </Row>
      {!completed && (
        <Row gutter={[5, 5]} align="middle" justify="center">
          <Col xl={8} xs={22}>
            <Card
              className="card-box"
              actions={
                [
                  // <Link to="/register">New User? Sign up please!</Link>,
                  // <Link to="/login">Already registered? Please Login!</Link>,
                ]
              }
            >
              Provide your correct username and we will send you insstructions
              at your registered email that how you can recover your lost
              password.
              <div className="box-pad fill-width fill-height">
                <Form
                  form={form}
                  name="forgot-password"
                  layout="vertical"
                  initialValues={{}}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item
                    label="Your Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your login username!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Recover my password
                    </Button>{" "}
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      )}

      {completed && (
        <Result
          status="success"
          title="Password recovery email has been sent"
          subTitle="We have sent you the password recovery details on your email. Please follow the instructions from that email in order to login with new password."
          extra={[
            <Button
              key="btn_login"
              type="primary"
              onClick={() => {
                navigate("/login");
                //props.history.push("/login");
                //window.location.href = "/login";
              }}
            >
              Log in now
            </Button>,
            <Button
              key="btn_home"
              type="primary"
              className="bg-default"
              onClick={() => {
                navigate("/login");
                //props.history.push("/");
                // window.location.href = "/";
              }}
            >
              Go to Home Page
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default ForgotPassword;
