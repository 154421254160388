import React, { useState, useEffect } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom";

import PublicHeader from "./PublicHeader";
import Text from "antd/lib/typography/Text";
import { Button, Col, Layout, Modal, Row, Space } from "antd";

import environment from "Environment";
import { LoadingPanel } from "./LoadingPanels";
import { isLoggedIn } from "Services/AuthService";
import { PrivacyPolicy } from "Pages/Public/privacy-policy/privacy-policy";

import "./PublicLayout.css";

const { Header, Content, Footer } = Layout;

const PublicLayout = (props) => {
  const [toggle, setToggle] = useState("");
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const onMenuClick = (name) => setToggle(toggle === name ? "" : name);
  var location = useLocation();
  useEffect(() => {
    console.log("public routes", props);
    //AuthService.logout();

    console.log("pathname", location.pathname);

    return () => {
      //cleanup
    };
  }, []);

  const handleTopMenuChanged = (nav) => {
    console.log("top menue changed");
  };

  let pRoutes = useRoutes(
    props?.routes &&
      props?.routes.map((route) => {
        return {
          path: `/${route.url}`,
          element: <route.component />,
        };
      })
  );

  return (
    <Layout className="center" style={{ minWidth: "90%" }}>
      <LoadingPanel />
      {(location.pathname === "/landing" ||
        location.pathname === "/privacy-policy" ||
        location.pathname === "/") && <PublicHeader />}
      <Content
        style={{
          padding: "0px",
          marginTop: "0px",
          backgroundColor: "white",
          alignContent: "middle",
        }}
      >
        <div
          style={{
            minHeight: "500px",
          }}
        >
          {/*
           CONTENTS GOES HERE
           
           */}
          {pRoutes}
        </div>
        {(location.pathname === "/landing" ||
          location.pathname === "/privacy-policy" ||
          location.pathname === "/") && (
          <Footer
            style={{
              backgroundColor: "#59cae8",
              color: "#fff",
              position: "relative",
              marginTop: "50px",
              padding: "30px",
              bottom: "0",
              minHeight: "250px",
            }}
          >
            <Row>
              <Col xs={24} md={24} className="text-center mt-2">
                <Link to="/">
                  <img src="./images/landing/footer-logo.png" width={200} />
                </Link>
                <br />
                <br />
                <Space size={30}>
                  <Link
                    to="https://www.instagram.com/offical_boardsales/"
                    footer-link
                  >
                    <img src="./images/landing/footer-insta-logo.svg" />
                  </Link>
                  <a
                    href="#contactus"
                    className="text-white text-bold text-large footer-link"
                  >
                    Contact Us
                  </a>
                  <Link
                    to={"/privacy-policy"}
                    //onClick={() => setShowPrivacyPolicy(true)}
                    className="text-white text-bold text-large footer-link"
                  >
                    Privacy Policy
                  </Link>
                </Space>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24} className="text-center">
                <br />
                Copyrights &copy; 2024 BoardSales.com
                <br />
              </Col>
            </Row>

            <Modal
              centered={true}
              open={showPrivacyPolicy}
              onCancel={() => setShowPrivacyPolicy(false)}
              footer={null}
              title={<></>}
            >
              <PrivacyPolicy />
              <br />
              <br />
              <center>
                <Button onClick={() => setShowPrivacyPolicy(false)}>
                  Close
                </Button>
              </center>
            </Modal>
          </Footer>
        )}
      </Content>
    </Layout>
  );
};
export default PublicLayout;
