import BaseApiService from "Services/BaseApiService";
import { Modal, Row, Col, Button, Space, Checkbox } from "antd";
import CustomDataGrid from "components/DevEx/CustomDataGrid";
import { useEffect, useState } from "react";
import environment from "Environment";
import { trackPromise } from "react-promise-tracker";
import { Tabs } from "antd/lib";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { alertify, notify } from "Services/ToastService";
import UserBoardForm from "../Products/UserBoardForm";
import UserGearForm from "../Products/UserGearForm";
import UserPaymentForm from "./UserPaymentForm";
const { RangePicker } = DatePicker;

var dateFormat = "DD/MM/YYYY";

const Payments = () => {
  const [boards, setBoards] = useState([]);
  const [gears, setGears] = useState([]);
  const [showFormPayment, setShowFormPayment] = useState(false);

  const [userPayment, setUserPayment] = useState();

  const [locations, setLocations] = useState();
  const [boardTypes, setBoardTypes] = useState();
  const [finSystems, setFinSystems] = useState();
  const [finSetups, setFinSetups] = useState();
  const [shapers, setShapers] = useState();

  const [dateRange, setDateRange] = useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);
  const [previousDateRange, setPrevioustDateRange] = useState();

  const [allTimesDateRange, setAllTimesDateRange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchLookups();
      await fetchBoardPayments();
    };
    fetchData();
  }, []);

  const fetchBoardPayments = async () => {
    console.log("Date Range", dateRange);
    if (!dateRange[0] || !dateRange[1]) {
      notify("Please select correct date range for the report");
      return;
    }
    let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
    let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.GET(`/Admin/payments/B/${sDate}/${eDate}`)
      );
      notify(message, status);
      console.log("Board payments", data);
      setBoards(data);
    } catch (error) {
      console.error("Failed to fetch boards:", error);
    }
  };

  const fetchGearPayments = async () => {
    console.log("Date Range", dateRange);
    if (!dateRange[0] || !dateRange[1]) {
      notify("Please select correct date range for the report");
      return;
    }
    let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
    let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.GET(`/Admin/payments/G/${sDate}/${eDate}`)
      );
      notify(message, status);
      console.log("Gear payments", data);
      setGears(data);
    } catch (error) {
      console.error("Failed to fetch gears:", error);
    }
  };

  const fetchLookups = async () => {
    const { data, message, status } = await trackPromise(
      BaseApiService.GET("/AP_Lookup")
    );
    setLocations(data.locations);
    setBoardTypes(data.boardTypes);
    setFinSystems(data.finSystems);
    setFinSetups(data.finSetups);
    setShapers(data.shapers);
  };

  const columns_boards = [
    {
      caption: "Payment Id",
      dataField: "paymentId",
      dataType: "number",
    },
    {
      caption: "Payment Date",
      dataField: "PaymentDate",
      dataType: "date",
    },

    {
      caption: "Refernce No",
      dataField: "referenceNo",
      dataType: "string",
    },

    {
      caption: "Amount",
      dataField: "amount",
      dataType: "number",
    },

    {
      caption: "Paid Amount",
      dataField: "chargedAmount",
      dataType: "number",
    },

    {
      caption: "Status",
      dataField: "response",
      dataType: "string",
    },

    {
      caption: "Board Id",
      dataField: "productId",
      dataType: "number",
    },
    {
      caption: "Seller Id",
      dataField: "sellerId",
      dataType: "number",
    },
    {
      caption: "Seller",
      dataField: "seller",
      dataType: "string",
    },

    {
      caption: "Board Title",
      dataField: "title",
      dataType: "string",
    },
    {
      caption: "Condition",
      dataField: "condition",
      cellRender: (item) => {
        let row = item.row.data;
        return row.condition === 1 ? "NEW" : "USED";
      },
    },

    {
      caption: "Board Price",
      dataField: "price",
      valueFormat: "currency",
      displayFormat: "$ {1}",
    },

    {
      caption: "Board Type",
      dataField: "boardType",
      dataType: "string",
    },
    {
      caption: "Location",
      dataField: "location",
      dataType: "string",
    },
    {
      caption: "Featured",
      dataField: "featured",
      dataType: "boolean",
    },

    {
      caption: "Vintage",
      dataField: "vintage",
      dataType: "boolean",
    },

    {
      caption: "Team Board",
      dataField: "teamBoard",
      dataType: "boolean",
    },
  ];

  const columns_gears = [
    {
      caption: "Payment Id",
      dataField: "paymentId",
      dataType: "number",
    },
    {
      caption: "Payment Date",
      dataField: "PaymentDate",
      dataType: "date",
    },

    {
      caption: "Refernce No",
      dataField: "referenceNo",
      dataType: "string",
    },

    {
      caption: "Amount",
      dataField: "amount",
      dataType: "number",
    },

    {
      caption: "Paid Amount",
      dataField: "chargedAmount",
      dataType: "number",
    },

    {
      caption: "Status",
      dataField: "response",
      dataType: "string",
    },
    {
      caption: "Refunded",
      dataField: "refunded",
      dataType: "boolean",
    },

    {
      caption: "Gear Id",
      dataField: "productId",
      dataType: "number",
    },
    {
      caption: "Seller Id",
      dataField: "sellerId",
      dataType: "number",
    },
    {
      caption: "Seller",
      dataField: "seller",
      dataType: "string",
    },

    {
      caption: "Gear Title",
      dataField: "title",
      dataType: "string",
    },
    {
      caption: "Condition",
      dataField: "condition",
      cellRender: (item) => {
        let row = item.row.data;
        return row.condition === 1 ? "NEW" : "USED";
      },
    },

    {
      caption: "Gear Price",
      dataField: "price",
      valueFormat: "currency",
      displayFormat: "$ {1}",
    },

    {
      caption: "Active",
      dataField: "active",
      dataType: "boolean",
    },

    {
      caption: "Sold",
      dataField: "isSold",
      dataType: "boolean",
    },
  ];

  const handleEditPayment = (usrGear) => {
    setUserPayment(usrGear);
    setShowFormPayment(true);
  };

  const handleSavePayment = async (updatedUserBoard) => {
    const { data, status, message } = await trackPromise(
      BaseApiService(`/Admin/UpdateBoardPayment`, updatedUserBoard)
    );

    alertify(message, status);

    setShowFormPayment(false);
    fetchBoardPayments();
  };

  const handleDeletePayment = async (obj) => {
    const { status, message } = await trackPromise(
      BaseApiService.POST(`/Admin/DeletePayment/${obj.id}`)
    );

    alertify(message, status);

    if (status) fetchBoardPayments();
  };

  const handleCancel = () => {
    setShowFormPayment(false);
  };

  return (
    <>
      <Row>
        <Col className="mr-auto">
          <h2> Payments </h2>
        </Col>
        <Col className="mb-2">
          <Space>
            <Checkbox
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  setPrevioustDateRange([...dateRange]);
                  setDateRange([dayjs("2024-01-01"), dayjs()]);
                } else {
                  setDateRange([...previousDateRange]);
                }
                setAllTimesDateRange(checked);
              }}
            />{" "}
            <span className="text-grey">All times payments!</span> <b>OR</b>
            <label className="text-small">Paid between: </label>
            <RangePicker
              disabled={allTimesDateRange}
              format={dateFormat}
              allowClear={true}
              allowEmpty={[true, true]}
              onCalendarChange={(e) => {
                setDateRange(e);
              }}
              value={dateRange}
              defaultValue={dateRange}
              //defaultValue={[moment(dateRange.sDate), moment(dateRange.eDate)]}
            />
            <Button
              onClick={async () => {
                await fetchBoardPayments();
                await fetchGearPayments();
              }}
            >
              Search
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <br />
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={<></>}
        className="flex"
        style={{ width: "100%" }}
        onLoad={fetchBoardPayments}
        onChange={async (tabKey) => {
          switch (tabKey) {
            case "boards":
              fetchBoardPayments();
              break;
            case "gears":
              fetchGearPayments();
              break;
            default:
              break;
          }
        }}
        items={[
          {
            label: "Surfboard payments",
            key: "boards",
            children: (
              <>
                <CustomDataGrid
                  data={boards}
                  columns={columns_boards}
                  ENTITY={"Surfboard Payment"}
                  ENTITY_PLURAL={"Surfboards Payments"}
                  editHandler={handleEditPayment}
                  deleteHandler={handleDeletePayment}
                  handleCancel={handleCancel}
                  canView={true}
                  canEdit={false}
                  canDelete={false}
                  doPaging={false}
                  canSelect={false}
                />
                <Modal
                  width={768}
                  open={showFormPayment}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {userPayment && (
                    <UserPaymentForm
                      data={userPayment}
                      onSave={handleSavePayment}
                      onCancel={handleCancel}
                    />
                  )}
                </Modal>
              </>
            ),
          },
          {
            label: "Gear Payments",
            key: "gears",
            children: (
              <>
                <CustomDataGrid
                  data={gears}
                  columns={columns_gears}
                  ENTITY={"Gear Payment"}
                  ENTITY_PLURAL={"Gear Payments"}
                  editHandler={handleEditPayment}
                  deleteHandler={handleDeletePayment}
                  handleCancel={handleCancel}
                  canView={true}
                  canEdit={false}
                  canDelete={false}
                  doPaging={false}
                  canSelect={false}
                />
                <Modal
                  width={768}
                  open={showFormPayment}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {userPayment && (
                    <UserPaymentForm
                      data={userPayment}
                      onSave={handleSavePayment}
                      onCancel={handleCancel}
                    />
                  )}
                </Modal>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default Payments;
