import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Select,
} from "antd";
import { LoadingPanelForPopup } from "Layout/LoadingPanels";
import { useEffect } from "react";

const UserBoardForm = ({ data, onSave, onCancel, lookups }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const onFinish = (values) => {
    onSave({ ...data, ...values });
  };

  return (
    <>
      <LoadingPanelForPopup />
      <h2>Edit Board</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={data}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="id"
              label="Board ID"
              rules={[{ required: true, message: "Please input Board ID!" }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="imagesPath" label="Images Path">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              //   rules={[{ required: true, message: "Please input Title!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="condition"
              label="Condition"
              //   rules={[{ required: true, message: "Please input Condition!" }]}
            >
              <Select
                options={[
                  { label: "New", value: 1 },
                  { label: "Old", value: 2 },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="boardType"
              label="Board Type"
              //   rules={[{ required: true, message: "Please input Board Type!" }]}
            >
              <Select
                options={
                  lookups.boardTypes &&
                  lookups.boardTypes.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="boardShapers"
              label="Board Shapers"
              //   rules={[{ required: true, message: "Please input Board Shapers!" }]}
            >
              <Select
                options={
                  lookups.shapers &&
                  lookups.shapers.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="finSystem"
              label="Fin System"
              //   rules={[{ required: true, message: "Please input Fin System!" }]}
            >
              <Select
                options={
                  lookups.finSystems &&
                  lookups.finSystems.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="finSetup"
              label="Fin Setup"
              //   rules={[{ required: true, message: "Please input Fin Setup!" }]}
            >
              <Select
                options={
                  lookups.finSetups &&
                  lookups.finSetups.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="surfCraftType"
              label="Surf Craft Type"
              //   rules={[{ required: true, message: "Please input Surf Craft Type!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="surfCraftWeight"
              label="Surf Craft Weight"
              //   rules={[{ required: true, message: "Please input Surf Craft Weight!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="length"
              label="Length"
              //   rules={[{ required: true, message: "Please input Length!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="width" label="Width">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="thickness"
              label="Thickness"
              //   rules={[{ required: true, message: "Please input Thickness!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="volume"
              label="Volume"
              //   rules={[{ required: true, message: "Please input Volume!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              //   rules={[{ required: true, message: "Please input Description!" }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="price"
              label="Price"
              //   rules={[{ required: true, message: "Please input Price!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="considerSwap" valuePropName="checked">
              <Checkbox>Consider Swap</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="location"
              label="Location"
              //   rules={[{ required: true, message: "Please input Location!" }]}
            >
              <Select
                options={
                  lookups.locations &&
                  lookups.locations.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="distance"
              label="Distance"
              //   rules={[{ required: true, message: "Please input Distance!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="isFeatured" valuePropName="checked">
              <Checkbox>Is Featured</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="teamBoard" valuePropName="checked">
              <Checkbox>Team Board</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="vintage" valuePropName="checked">
              <Checkbox>Vintage</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="dateAdded" label="Date Added">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="isPosted" valuePropName="checked">
              <Checkbox>Is Posted</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="isSold" valuePropName="checked">
              <Checkbox>Is Sold</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="purchaseDate" label="Purchase Date">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="soldToUserId"
              label="Sold To User ID"
              //   rules={[{ required: true, message: "Please input Sold To User ID!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="likedByUserId"
              label="Liked By User ID"
              //   rules={[{ required: true, message: "Please input Liked By User ID!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lengthInCentimeter"
              label="Length In Centimeter"
              //   rules={[{ required: true, message: "Please input Length In Centimeter!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="userId"
              label="User ID"
              rules={[{ required: true, message: "Please input User ID!" }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="userName" label="User">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel} style={{ marginLeft: "8px" }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserBoardForm;
