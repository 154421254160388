import {
  Col,
  Dropdown,
  Menu,
  message,
  Popover,
  Radio,
  Row,
  Space,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "assets/images/logo.png";
import logo_icon from "assets/images/logo.png";

import { Button } from "antd";
import { CaretDownFilled, DownOutlined, MenuOutlined } from "@ant-design/icons";
import Profile from "./Profile";
import environment from "Environment";

const NavHeader = ({
  layout,
  modules,
  curModule,
  onTopMenuChanged,
  onMenuButtonClicked,
}) => {
  const [selectedNav, setSelectedNav] = useState();
  const [subMenuVisibile, setSubMenuVisibile] = useState(false);

  const handleNavigationClicked = (e) => {
    //  message.info(`Selected module is ${curNavItem.label}`);
    setSelectedNav(e.target.value);
  };

  const GetSubMenu = (subMenu) => {
    return (
      <div>
        {subMenu &&
          subMenu.map((sm, i) => {
            return (
              <span key={`sm_${i}`}>
                <NavLink
                  className="html-link"
                  onClick={() => onTopMenuChanged(sm.path)}
                  to={sm.path}
                >
                  <div>{sm.label}</div>
                </NavLink>
                <hr size="1" style={{ color: "#eee" }} />
              </span>
            );
          })}
      </div>
    );
  };
  return (
    <>
      <Row align="bottom" justify="start" wrap style={{ zIndex: 99999 }}>
        <Col span={24}>
          <div className="flex">
            <Space size={layout === "full" ? 8 : 2}>
              {layout !== "overlay" &&
                modules &&
                modules
                  .filter((m) => m.showOnTop !== false)
                  .map((nav, i) => {
                    return (
                      (nav.show && nav.items && nav.items.length > 0 && (
                        <Popover
                          key={`mnu_${i}`}
                          content={GetSubMenu(nav.items)}
                          trigger="hover"
                          placement="bottomRight"
                        >
                          <div
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            className={`top-menu ${
                              nav.path === (curModule && curModule.path)
                                ? "top-menu-active"
                                : ""
                            }`}
                          >
                            {/* <i className={nav.icon} style={{ fontSize: layout === "mini" ? "16px" : "24px" }}></i> */}
                            <div>
                              {nav.label} <CaretDownFilled />
                            </div>
                          </div>
                        </Popover>
                      )) || (
                        <NavLink
                          key={`tp-nav-${nav.label}`}
                          to={nav.path}
                          onClick={() => {
                            onTopMenuChanged(nav.path);
                          }}
                        >
                          <div
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            className={`top-menu ${
                              nav.path === (curModule && curModule.path)
                                ? "top-menu-active"
                                : ""
                            }`}
                          >
                            {/* <i className={nav.icon} style={{ fontSize: layout === "mini" ? "16px" : "24px" }}></i> */}
                            <div>{nav.label}</div>
                          </div>
                        </NavLink>
                      )
                    );
                  })}
              {layout === "overlay" && (
                <Button
                  className="top-menu-button bg-success ml-2"
                  icon={<MenuOutlined />}
                  shape="circle"
                  onClick={() => onMenuButtonClicked(true)}
                  size={40}
                />
              )}
            </Space>

            <div
              className="ml-auto flex"
              style={{ float: "right", textAlign: "right", marginTop: "0px" }}
            >
              <Link to="/app/dashboard">
                <img src={logo_icon} style={{ width: "50px" }} />
              </Link>
              <span className="ml-3 push-right">
                <Profile layout={layout} onLinkClicked={onTopMenuChanged} />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default NavHeader;
