import { Button, Card, Divider, Dropdown, Menu, Space, Tooltip } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "Services/AuthService";
import DropdownButton from "antd/lib/dropdown/dropdown-button";

import { MenuOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";

const SideMenu = ({ topNav, collapsed, onMenuChanged, onOrgChanged }) => {
  const [menu, setMenu] = useState();
  const [curOrg, setCurOrg] = useState("Service Vault");

  // useEffect(() => {
  //   // let navMenu = null;
  //   // const path = window.location.pathname;
  //   // let module = path.split("/").length >= 3 ? path.split("/")[2] : "";
  //   // //console.log("Current Module", module);
  //   // if (module === "") {
  //   //   navMenu = Navigation.filter((n) => n.path === "/app/e-docs");
  //   // } else if (module !== "") {
  //   //   navMenu = Navigation.filter((n) => n.path === "/app/" + module)[0];
  //   // }
  //   // setMenu(navMenu);
  //   // //console.log("menu", navMenu);
  // }, [topNav]);

  const handleMenuClick = (e) => {
    let path = e.key;
    //let nav = menu.items && menu.items.filter((n) => n.path === e.key)[0];
    if (path === "logout") {
      AuthService.logout();
      window.location.href = "/session-expired";
      return;
    }
    onMenuChanged(path);
  };

  return (
    <>
      <div className={``}>
        <Menu
          theme="light"
          mode="inline"
          onClick={handleMenuClick}
          selectedKeys={[window.location.pathname]}
        >
          {topNav &&
            topNav.map((m, i) => {
              return (
                <>
                  {m.items && (
                    <SubMenu
                      key={`sm_${i}`}
                      title={
                        <span style={{ marginLeft: "10px" }}>{m.label}</span>
                      }
                      icon={getIcon(m.icon)}
                    >
                      {m.items.map((sm) => {
                        return (
                          <Menu.Item key={`${sm.path}`} icon={getIcon(sm.icon)}>
                            {sm.label}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  )}
                  {!m.items && (
                    <Menu.Item
                      key={`${m.path}`}
                      title={m.label}
                      icon={getIcon(m.icon)}
                    >
                      {m.label}
                    </Menu.Item>
                  )}
                </>
              );
            })}

          <Menu.Item
            key={`logout`}
            title={"Log out"}
            icon={getIcon("fas fa-sign-out-alt")}
          >
            <span className="text-danger text-bold">Log out</span>
          </Menu.Item>
        </Menu>
      </div>
      <div style={{ marginBottom: "200px" }}>
        {/* NOTE: Empty div to adjust scroll bar height. DO NOT REMOVE IT */}
      </div>
    </>
  );
};

export default SideMenu;

function getIcon(ico) {
  return <i className={ico} />;
}
