import "./assets/styles/antd-custom.less";
import "./App.css"; //dont move it aboe antd css
import { Routes } from "react-router-dom";
//import { withResizeDetector } from "react-resize-detector";
import React, { useEffect, useState } from "react";
import { routes } from "./Routes.js";
import ProtectedLayout from "./Layout/ProtectedLayout";
import PublicLayout from "./Layout/PublicLayout";
import AuthService from "./Services/AuthService";

import axios from "axios";
import { User } from "./types/User";
import { Button } from "antd";

import Navigation from "./Data/Navigation.json";
import BaseApiService from "Services/BaseApiService";

// interface AppProps {
//   title: string;
//   hideLoader: any;
// }

const App = ({ title, hideLoader }: any) => {
  const [layout, setLayout] = useState("full");
  const [filteredNavigation, setFilteredNavigation] = useState(null);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    hideLoader();
    handleResize();
    window.addEventListener("resize", handleResize);

    let userRole = AuthService.getUserRole();
    let userModules = AuthService.getUserModules();
    setFilteredNavigation(filterNavigation(userRole));

    // const getUsers = async () => {
    //   //   try{
    //   const { data, message, status } = await BaseApiService.GET("/users", {
    //     id: 2,
    //   }); //await axios.get("https://randomuser.me/api/?results=10");

    //   setUsers(data.results);
    //   console.log(data.results);
    //   //alert("test");
    //   //   }
    // };
    // getUsers();

    return () => {
      //cleanup;
    };
  }, []);

  const handleResize = () => {
    //console.log("APP Width", window.innerWidth);
    setLayout(
      window.innerWidth >= 1200
        ? "full"
        : window.innerWidth <= 1200 && window.innerWidth >= 1024
        ? "small"
        : window.innerWidth <= 1023 && window.innerWidth >= 768
        ? "mini"
        : "overlay"
    );
  };

  function filterNavigation(uRole: string): any {
    let nav = Navigation.filter(
      (n: any) => 1 === 1 && (!n.roles || n.roles.includes(uRole))
    ).map((m: any) => {
      return {
        ...m,
        items:
          m.items &&
          m.items.filter((sm: any) => !sm.roles || sm.roles.includes(uRole)),
      };
    });

    return nav;
  }

  const publicRoutes = routes.filter((r) => r.auth === "public");
  const protectedRoutes = routes.filter(
    (r) =>
      r.auth !== "public" &&
      (!r.roles || r.roles.includes(AuthService.getUserRole()))
  );
  //const landingRoute = [{ url: "/", auth: "public", component: Landing }];

  const isLoggedIn = AuthService.isLoggedIn();
  const userRole = AuthService.getUserRole();
  console.log("isLoggedIn", isLoggedIn);
  console.log("userRole", userRole);

  return (
    <>
      {(isLoggedIn && filteredNavigation !== null && (
        <ProtectedLayout
          layout={layout}
          routes={protectedRoutes}
          userRole={userRole}
          navigation={filteredNavigation}
        />
      )) || <PublicLayout layout={layout} routes={publicRoutes} />}
    </>

    // <div className="App">
    //   <header className="App-header">
    //     {users.map((user: User) => {
    //       return (
    //         <>
    //           {" "}
    //           {user.email} <hr />
    //         </>
    //       );
    //     })}
    //     <Button type="primary">Button</Button>
    //   </header>
    // </div>
  );
};

export default App;
