import { Form, Input, Button, Row, Col, Checkbox, Select } from "antd";
import { LoadingPanelForPopup } from "Layout/LoadingPanels";
import { useEffect } from "react";

const UsersForm = ({ data, onSave, onCancel, lookups }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const onFinish = (values) => {
    onSave({ ...data, ...values });
  };

  return (
    <>
      <LoadingPanelForPopup />
      <h2>Edit User</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={data}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="userId"
              label="User ID"
              rules={[{ required: true, message: "Please input User ID!" }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="username"
              label="User Name"
              rules={[{ required: true, message: "Please input User Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="location" label="Location">
              <Select
                options={
                  lookups.locations &&
                  lookups.locations.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="profilePicture" label="Profile Picture">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="role" label="Role">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="distance" label="Distance">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="boardType" label="Board Type">
              <Select
                options={
                  lookups.boardTypes &&
                  lookups.boardTypes.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))
                }
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="boardLength" label="Board Length">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="getStartedCompleted" valuePropName="checked">
              <Checkbox>Get Started Completed</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="verified" valuePropName="checked">
              <Checkbox>Verified</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel} style={{ marginLeft: "8px" }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UsersForm;
