import { Button, Result } from "antd";
import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "Services/API/UserService";
import BaseApiService from "Services/BaseApiService";
import { notify } from "Services/ToastService";
import headerLogo from "assets/images/header-logo.png";

function VerifyUser(props) {
  const navigate = useNavigate();
  let { token } = useParams();
  const [verified, setVerified] = useState(0); //0 loading, 1, verification failed 2 = verified
  useEffect(() => {
    //alert(token);

    const verifyUser = async () => {
      const url = `/admin/verify/${token}`;

      const { data, status, message } = await trackPromise(
        //UserService.Verify(token
        BaseApiService.GET(url)
      );

      notify(message, status);
      if (status && data) {
        setVerified(2);
      } else {
        setVerified(1);
      }
    };
    verifyUser();
  }, []);
  return (
    <>
      <img className="ml-auto" src={headerLogo} />
      <br />
      <Result
        status={verified == 2 ? "success" : "warning"}
        title={
          verified == 1
            ? "Failed to verify your account registration"
            : verified == 2
            ? "You have successfully verified your Boardsales account"
            : "ٰWe are validating your information. Please wait."
        }
        subTitle={
          verified == 1
            ? "Please contact our support about your problem"
            : verified == 2
            ? "You can consume all the features on boardsales mobile application."
            : "Thanks for your patience"
        }
      />
      <h2></h2>
    </>
  );
}
export default VerifyUser;
