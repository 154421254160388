import React, { useState, useEffect } from "react";
import AuthService from "Services/AuthService";
import { Form, Input, Button, Row, Col, Space, Card, Typography } from "antd";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { alertify } from "Services/ToastService";
import { intervalToDuration } from "date-fns";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./Landing.css";
import { Modal } from "antd/lib";
import { Link } from "react-router-dom";
import MyDemo from "myfunc";
import MyHeading from "demo";

const { Title } = Typography;

const Landing = () => {
  const [toggle, setToggle] = useState("");

  const [contactName, setContactName] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [contactMessage, setContactMessage] = useState();
  const [showAccessForm, setShowAccessForm] = useState(false);

  const [vipAccessName, setVipAccessName] = useState();
  const [vipAccessEmail, setVipAccessEmail] = useState();

  const [form] = Form.useForm();
  const [vipForm] = Form.useForm();

  const [rDays, setRDays] = useState("0");
  const [rHours, setRHours] = useState("0");
  const [rMinutes, setRMinutes] = useState("0");
  const [rSeconds, setRSeconds] = useState("0");

  useEffect(() => {
    AuthService.logout();
    initiateTimer();
  }, []);

  function initiateTimer() {
    const t = setInterval(() => {
      setRDays(remainingDays());
      setRHours(remaining().hours);
      setRMinutes(remaining().minutes);
      setRSeconds(remaining().seconds);
    }, 1000);
  }
  const remaining = () => {
    const eDate = "2024-10-01";
    const today = new Date();
    const endDate = new Date(eDate);
    return intervalToDuration({
      start: today,
      end: endDate,
    });
  };

  const remainingDays = () => {
    let date1 = new Date();
    let date2 = new Date("10/01/2024");

    // Calculating the time difference
    // of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    return Difference_In_Days;
  };

  const renderCaraousel = () => {
    return (
      <>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={5000}
          centerMode={false}
          className=""
          containerClass=""
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass="carousel-item"
          keyBoardControl
          minimumTouchDrag={50}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={true}
          renderDotsOutside={true}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 6,
              partialVisibilityGutter: 10,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 2,
              partialVisibilityGutter: 15,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 3,
              partialVisibilityGutter: 15,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <img
            src="/images/landing/brand1.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand2.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand3.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand4.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand5.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand6.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand7.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand8.png"
            width={175}
            className="carouselItem"
          />
          <img
            src="/images/landing/brand9.png"
            width={175}
            className="carouselItem"
          />
        </Carousel>
      </>
    );
  };

  const renderAppScreens = () => {
    return (
      <center>
        <Row gutter={[20, 20]} className="mobile-app-screens">
          <Col xs={24} md={8} className="app-screens">
            <h2>
              <span className="app-free push-right mr-3">Free</span>
              <br />
              <span className="download-text">Download</span>
            </h2>
            <div className="mobile-app">
              <img src="/images/landing/google-play-button.jpg" width={160} />
              <img src="/images/landing/app-store-button.jpg" width={160} />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <img src="/images/landing/app-screen1.png" width="100%" />
          </Col>
          <Col xs={12} md={4}>
            <img src="/images/landing/app-screen2.png" width="100%" />
          </Col>
          <Col xs={12} md={4}>
            <img src="/images/landing/app-screen3.png" width="100%" />
          </Col>
          <Col xs={12} md={4}>
            <img src="/images/landing/app-screen4.png" width="100%" />
          </Col>
        </Row>
      </center>
    );
  };

  const onContactSubmit = async () => {
    try {
      await form.validateFields();
      const formData = {
        access_key: "0d30997b-75c1-47c9-9a21-c9c252cc0cfa", //"50ecbe4f-ffb3-4b52-b349-532718305597", //danny's id
        type: "BoardSales - Contact Form Submitted",
        name: contactName,
        email: contactEmail,
        phone: contactPhone,
        message: contactMessage,
      };

      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log("Contact Us form response", data);

      if (data.success) {
        alertify(
          "Thanks for showing interest by submitting your message. Our support team will contact you soon for further instructions.",
          true
        );
        form.resetFields();
      } else {
        alertify(
          "System failed to submit your message at this time. Please try later on",
          false
        );
      }
    } catch (error) {
      console.error("Validation failed.", error);
    }
  };

  const onVIPAccessSubmit = async () => {
    try {
      await vipForm.validateFields();
      const formData = {
        access_key: "0d30997b-75c1-47c9-9a21-c9c252cc0cfa", //"50ecbe4f-ffb3-4b52-b349-532718305597", //danny's id
        type: "BoardSales - Pre-Register form sumitted",
        name: vipAccessName,
        email: vipAccessEmail,
      };

      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log("VIP Request form response", data);

      if (data.success) {
        alertify(
          "Thanks for showing interest by submitting your request for VIP Access. Our support team will contact you soon for further information.",
          true
        );
        vipForm.resetFields();
        setShowAccessForm(false);
      } else {
        alertify(
          "System failed to submit your message at this time. Please try later on",
          false
        );
      }
    } catch (error) {
      console.error("Validation failed.", error);
    }
  };

  // const getRemainingTime = () => {
  //   setRDays(remainingDays());
  //   setRHours(remaining().hours);
  //   setRMinutes(remaining().minutes);
  //   setRSeconds(remaining().seconds);
  // };

  function pad(str, leadingZeros) {
    str = str ? str.toString() : "0";
    return str.length < leadingZeros ? pad("0" + str, leadingZeros) : str;
  }

  return (
    <>
      <div className="m-0">
        <Row>
          <Col md={24} xs={24}>
            <img src="/images/landing/header.png" width="100%" />
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={24}>
            <h1 className="text-center intro-line">
              From first timers to seasoned surfers,{" "}
              <span className="app-name">BOARDSALES</span> streamlines the
              surfboard buying and selling experience for everyone!
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            md={24}
            xs={24}
            className="mt-5 timer _rectangleParent_czo8e_297 text-white text-center"
          >
            <Row>
              <Col span={24}>
                <center>
                  <h1 className="coming-soon">COMING SOON</h1>
                  <span></span>
                </center>
              </Col>
            </Row>
            <div className="remaining-time px-3">
              <Row className="flex">
                <Col span={6}>
                  <>
                    <h1>
                      <label className="days">{pad(rDays, 2)}</label>
                      <br />
                      <span>DAYs</span>
                    </h1>
                  </>
                </Col>
                <Col span={6}>
                  <>
                    <h1>
                      <label className="hour">{pad(rHours, 2)}</label>
                      <br />
                      <span>HOURs</span>
                    </h1>
                  </>
                </Col>
                <Col span={6}>
                  <>
                    <h1>
                      <label className="mins">{pad(rMinutes, 2)}</label>
                      <br />
                      <span>MINs</span>
                    </h1>
                  </>
                </Col>
                <Col span={6}>
                  <>
                    <h1>
                      <label className="secs">{pad(rSeconds, 2)}</label>
                      <br />
                      <span>SECs</span>
                    </h1>
                  </>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <br />

        <Row className="mt-5">{renderAppScreens()}</Row>
        <br />
        <Row>
          <Col md={24} xs={24}>
            <h1 className="text-center contact-line">
              We'd Love to <span>Hear from You</span>
            </h1>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12} className="text-center">
            <img src="/images/landing/contact-us-image.png" width="100%" />
          </Col>
          <Col
            xs={24}
            md={12}
            className="bg-white form"
            id="contactus"
            name="contactus"
          >
            <div className="contactUs">
              <Form form={form} onFinish={onContactSubmit}>
                <Row gutter={[20, 10]} className="contactUs-form my-5 p-5">
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="input"
                      name="name"
                      rules={[
                        { required: true, message: "Please enter your name!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Name"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email!" },
                        { type: "email", message: "Invalid email address!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <MailOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item name="phone">
                      <Input
                        prefix={
                          <PhoneOutlined className="site-form-item-icon" />
                        }
                        placeholder="Phone"
                        value={contactPhone}
                        onChange={(e) => setContactPhone(e.target.value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your message!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Message"
                        value={contactMessage}
                        onChange={(e) => setContactMessage(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item className="submit-btn text-center">
                      <Button type="primary" htmlType="submit">
                        Send Message
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <p className="box-text">
              Whether you're curious about features, pricing or even press -
              we're ready to answer any and all questions
            </p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={24} xs={24}>
            <h1 className="text-center contact-line">
              All <span>Brands</span> are here!
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{renderCaraousel()}</Col>
        </Row>
      </div>

      <Modal
        centered={true}
        open={showAccessForm}
        onCancel={() => setShowAccessForm(false)}
        footer={null}
        title={<h2 className="inline">Your contact details</h2>}
      >
        <Form form={vipForm} onFinish={onVIPAccessSubmit}>
          <Row gutter={[30, 30]} className="contactUs-form">
            <Col xs={24} md={24}>
              <Form.Item
                type="hidden"
                value={"VIP Access Request"}
                name="Type"
              />
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Name"
                  value={vipAccessName}
                  onChange={(e) => setVipAccessName(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Invalid email address!" },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value={vipAccessEmail}
                  onChange={(e) => setVipAccessEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item className="submit-btn">
                <center>
                  <Button type="primary" htmlType="submit">
                    <h2>Send Message</h2>
                  </Button>
                </center>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default Landing;
