import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Select,
} from "antd";
import { InputNumber } from "antd/lib";
import { LoadingPanelForPopup } from "Layout/LoadingPanels";
import { useEffect } from "react";

const UserGearForm = ({ data, onSave, onCancel, lookups }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const onFinish = (values) => {
    onSave({ ...data, ...values });
  };

  return (
    <>
      <LoadingPanelForPopup />
      <h2>Edit Gear</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={data}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="id"
              label="Gear ID"
              rules={[{ required: true, message: "Please input Gear ID!" }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="imagesPath" label="Images Path">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              //   rules={[{ required: true, message: "Please input Title!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="condition"
              label="Condition"
              //   rules={[{ required: true, message: "Please input Condition!" }]}
            >
              <Select
                options={[
                  { label: "New", value: 1 },
                  { label: "Old", value: 2 },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              //   rules={[{ required: true, message: "Please input Description!" }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="price"
              label="Price"
              //   rules={[{ required: true, message: "Please input Price!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="dateAdded" label="Date Added">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="isPosted" valuePropName="checked">
              <Checkbox>Posted?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="isSold" valuePropName="checked">
              <Checkbox>Sold</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="purchaseDate" label="Purchase Date">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="soldToUserId"
              label="Sold To User ID"
              //   rules={[{ required: true, message: "Please input Sold To User ID!" }]}
            >
              <InputNumber value={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="isArchive" valuePropName="checked">
              <Checkbox>Archived</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="userId"
              label="User ID"
              rules={[{ required: true, message: "Please input User ID!" }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="username" label="Username">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel} style={{ marginLeft: "8px" }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserGearForm;
