import { Form, Input, Button, Row, Col, Space, Modal, Menu } from "antd";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo.png";

import logo_icon from "assets/images/logo.png";

import Navigation from "Data/Navigation.json";
import Profile from "./Profile";
import environment from "Environment";
import { trackPromise } from "react-promise-tracker";
import { alertify } from "Services/ToastService";
import "./PublicLayout.css";

const PublicHeader = ({ layout, onMenuChanged }) => {
  //console.log("NavHeader Layour", layout);
  //const [current, setCurrent] = useState("dashboard");
  const [curNavItem, setCurNavItem] = useState();
  const [path, setPath] = useState();
  const [curMenu, setCurMenu] = useState();

  const [showAccessForm, setShowAccessForm] = useState(false);

  const [vipForm] = Form.useForm();

  const [vipAccessName, setVipAccessName] = useState();
  const [vipAccessEmail, setVipAccessEmail] = useState();

  useEffect(() => {
    let p = window.location.pathname.split("/");
    let cm = p && p.length >= 2 && p[1] !== "" ? p[1] : "dashboard";
    let cnav = Navigation.filter((n) => n.path === "/" + cm)[0];

    setPath(p);
    setCurMenu(cm);
    setCurNavItem(cnav);
  }, [window.location.pathname]);

  const onVIPAccessSubmit = async () => {
    try {
      await vipForm.validateFields();
      const formData = {
        access_key: "0d30997b-75c1-47c9-9a21-c9c252cc0cfa", //"50ecbe4f-ffb3-4b52-b349-532718305597", //danny's id
        type: "BoardSales - Pre-Register form sumitted",
        name: vipAccessName,
        email: vipAccessEmail,
      };

      const response = await trackPromise(
        fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        })
      );

      const data = await response.json();
      console.log("VIP Request form response", data);

      if (data.success) {
        alertify(
          "Thanks for showing interest by submitting your request for VIP Access. Our support team will contact you soon for further information.",
          true
        );
        vipForm.resetFields();
        setShowAccessForm(false);
      } else {
        alertify(
          "System failed to submit your message at this time. Please try later on",
          false
        );
      }
    } catch (error) {
      console.error("Validation failed.", error);
    }
  };
  const handleButtonClick = (e) => {
    // message.info(`Selected module is ${curNavItem.label}`);
  };
  const handleMenuClick = (e) => {
    // let cm = e.key;
    // let cnav = Navigation.filter((n) => n.path === cm)[0];
    // setCurNavItem(cnav);
    // onMenuChanged(cnav);
  };
  const menu_features = (
    <Menu onClick={handleMenuClick}>
      {Navigation.map((nav) => {
        return (
          <Menu.Item key={`${nav.path}`}>
            <div>
              <i className={nav.icon} style={{ fontSize: "18px" }}></i>
              <span style={{ float: "right", marginLeft: "10px" }}>
                {nav.label}
              </span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      <Row>
        <Col md={8} xs={24} className="main-heading">
          <Link to={"/"} style={{ color: "black" }}>
            <Space>
              <img src="/images/landing/logo.png" width={50} />
              <div>
                <h1 className="text-bold inline text-black">BOARDSALES</h1>
                <div class="inline text-blue text-center com">.com</div>
              </div>
            </Space>
          </Link>
        </Col>
        <Col md={8} xs={24} className="header-heading text-center">
          <button
            className="bg-white pre-regiser-btn"
            onClick={() => setShowAccessForm(true)}
          >
            Pre-Register Now
          </button>
        </Col>
        <Col
          md={8}
          xs={0}
          className="header-heading text-center push-center mt-2"
        >
          <p>Discover Your Ideal Board in a snap</p>
        </Col>
        <Col
          md={0}
          xs={22}
          push={1}
          pull={1}
          className="header-heading text-center push-center top-curve "
        >
          <p>Discover Your Ideal Board in a snap</p>
        </Col>
      </Row>
      <Modal
        centered={true}
        open={showAccessForm}
        onCancel={() => setShowAccessForm(false)}
        footer={null}
        title={<h2 className="inline">Your contact details</h2>}
      >
        <Form form={vipForm} onFinish={onVIPAccessSubmit}>
          <Row gutter={[30, 30]} className="contactUs-form">
            <Col xs={24} md={24}>
              <Form.Item
                type="hidden"
                value={"VIP Access Request"}
                name="Type"
              />
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Name"
                  value={vipAccessName}
                  onChange={(e) => setVipAccessName(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Invalid email address!" },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value={vipAccessEmail}
                  onChange={(e) => setVipAccessEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item className="submit-btn">
                <center>
                  <Button type="primary" htmlType="submit">
                    <h2>Send Message</h2>
                  </Button>
                </center>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default PublicHeader;
