import _ from "lodash";
// import md5 from "md5";
// import moment from "moment";
import environment from "Environment";
import Logger from "./LogService";
import { getAuthToken } from "./AuthService";
import axios from "axios";

const API_BASE_URL = environment.API.BASE_URL;
const API_DEFAULT_VERSION = environment.API.VERSION;
const API_TIMEOUT = environment.API.API_TIMEOUT;

export function ApiResponse({ data, status, statusText, headers }, isFile) {
  if (isFile) {
    return {
      data: data || [],
      //status: status >= 400 ? false : true,
      status: true,
      message: "File retrieved successfully",
      headers,
    };
  } else {
    return {
      data: (data && data.data) || [],
      //status: status >= 400 ? false : true,
      status: (data && data.status) || false,
      message: (data && data.message) || statusText,
      headers,
    };
  }
}

export function BadResponse(ex) {
  if (ex.response) {
    return ApiResponse(ex.response);
  } else {
    return ApiResponse({
      data: [],
      status: false,
      message: ex.message || "Issues with server connection",
      headers: {},
    });
  }
}

export function ExtractFileName(contentDispositionValue) {
  var filename = "";
  if (
    contentDispositionValue &&
    contentDispositionValue.indexOf("attachment") !== -1
  ) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(contentDispositionValue);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  return filename;
}

class BaseApiService {
  static async ApiRequest(webMethod, apiUrl, postData, apiVersion, isFormData) {
    //const axios = require("axios");
    const authToken = getAuthToken();
    try {
      let status = false;
      //const axios = require("axios").default;
      const axios_config = {
        method: webMethod,
        baseURL: API_BASE_URL,
        url: `${apiVersion || API_DEFAULT_VERSION}${apiUrl}`,
        data: postData,
        //params: postData,
        timeout: API_TIMEOUT,
        responseType: "json",
        crossDomain: true,
        contentType: isFormData ? "multipart/form-data" : "application/json",
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": isFormData
            ? "multipart/form-data"
            : "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const httpResponse = await axios(axios_config)
        .then((res) => {
          status = true;
          console.log("AXIOS Call Success", { ...axios_config });
          return res;
        })
        .catch((err) => {
          status = false;
          console.log("AXIOS Call Failure", err, err.config);
          return err;
        });

      //const { response } = httpResponse;
      return status ? ApiResponse(httpResponse) : BadResponse(httpResponse);
    } catch (ex) {
      return ApiResponse({
        data: [],
        status: false,
        message: ex.message,
        headers: {},
      });
    }
  }

  static async ApiRequestLarge(webMethod, apiUrl, postData, apiVersion) {
    const axios = require("axios");
    const authToken = getAuthToken();
    try {
      let status = false;
      //const axios = require("axios").default;
      const axios_config = {
        method: webMethod,
        baseURL: API_BASE_URL,
        url: `${apiVersion || API_DEFAULT_VERSION}${apiUrl}`,
        data: postData,
        //params: postData,
        timeout: API_TIMEOUT,
        responseType: "json",
        crossDomain: true,
        contentType: "application/json",
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const httpResponse = await axios(axios_config)
        .then((res) => {
          status = true;
          console.log("AXIOS Call Success", { ...axios_config });
          return res;
        })
        .catch((err) => {
          status = false;
          console.log("AXIOS Call Failure", err, err.config);
          return err;
        });

      //const { response } = httpResponse;
      return status ? ApiResponse(httpResponse) : BadResponse(httpResponse);
    } catch (ex) {
      return ApiResponse({
        data: [],
        status: false,
        message: ex.message,
        headers: {},
      });
    }
  }

  static async DownloadFile(webMethod, apiUrl, postData, isFile, apiVersion) {
    const axios = require("axios");
    const authToken = getAuthToken();
    try {
      let status = false;
      //const axios = require("axios").default;
      const axios_config = {
        method: webMethod,
        baseURL: API_BASE_URL,
        url: `${apiVersion || API_DEFAULT_VERSION}${apiUrl}`,
        data: postData,
        params: postData,
        timeout: API_TIMEOUT,
        responseType: isFile ? "blob" : "json",
        crossDomain: true,
        contentType: "application/json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await axios(axios_config)
        .then((res) => {
          status = true;
          //extract file name from Content-Disposition header
          //var filename = ExtractFileName(res.headers["content-disposition"]);
          //console.log("File name", filename || 'default.pdf');
          //invoke 'Save As' dialog
          //saveAs(res.data, filename);

          let filename = res.headers["x-filename"];

          filename =
            (filename &&
              filename.substring(filename.indexOf("=") + 1).trim()) ||
            "downloaded_file";
          return {
            data: res.data,
            status: true,
            message: "file retrieved successfully",
            filename: filename,
          };
        })
        .catch((err) => {
          status = false;
          console.log("AXIOS Call Failure", err, err.config);
          return {
            data: [],
            status: false,
            message: "Failed to reach the server to download requested file",
          };
        });

      return response;
    } catch (ex) {
      return ApiResponse({
        data: [],
        status: false,
        message: ex.message,
        headers: {},
      });
    }
  }

  static async GET(url, postData) {
    return await this.ApiRequest("get", url, postData);
  }
  static async POST(url, postData) {
    return await this.ApiRequest("post", url, postData);
  }
  static async PUT(url, postData) {
    return await this.ApiRequest("put", url, postData);
  }
  static async DELETE(url, postData) {
    return await this.ApiRequest("delete", url, postData);
  }
  static async DOWNLOAD(url, postData) {
    return await this.DownloadFile("post", url, postData, true);
  }

  static async UPLOAD(url, postData) {
    return await this.ApiRequest("post", url, postData, null, true);
  }
}

export default BaseApiService;
