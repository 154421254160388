import BaseApiService from "Services/BaseApiService";
import { Modal, message, Row, Col, Space, Button, Checkbox } from "antd";
import CustomDataGrid from "components/DevEx/CustomDataGrid";
import { useEffect, useState } from "react";
import UsersForm from "./users-form";
import environment from "Environment";
import { trackPromise } from "react-promise-tracker";
import { alertify, notify } from "Services/ToastService";
import dayjs from "dayjs";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

var dateFormat = "DD/MM/YYYY";

const Users = () => {
  const [users, setUsers] = useState();
  const [showForm, setShowForm] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [locations, setLocations] = useState();
  const [boardTypes, setBoardTypes] = useState();
  const [finSystems, setFinSystems] = useState();
  const [finSetups, setFinSetups] = useState();
  const [shapers, setshapers] = useState();

  const [dateRange, setDateRange] = useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);

  const [previousDateRange, setPrevioustDateRange] = useState();

  const [allTimesDateRange, setAllTimesDateRange] = useState(false);

  // const dateRangeChanged = (e) => {
  //   setDateRange(e);
  // };

  useEffect(() => {
    console.log("UseEffect");
    const fetchData = async () => {
      await fetchLookups();
      await fetchUsers();
    };
    fetchData();
  }, []);

  const fetchUsers = async () => {
    try {
      console.log("Date Range", dateRange);
      if (!dateRange[0] || !dateRange[1]) {
        notify("Please select correct date range for the report");
        return;
      }
      let sDate = dayjs(dateRange[0]).format("YYYY-MM-DD");
      let eDate = dayjs(dateRange[1]).format("YYYY-MM-DD");

      const { data, message, status } = await trackPromise(
        BaseApiService.GET(`/admin/users/${sDate}/${eDate}`)
      );
      //  console.log(data);

      setUsers(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const fetchLookups = async () => {
    setLoading(true);
    try {
      const { data, message, status } = await trackPromise(
        BaseApiService.GET("/AP_Lookup")
      );

      setLocations(data.locations);
      setBoardTypes(data.boardTypes);
      setFinSystems(data.finSystems);
      setFinSetups(data.finSetups);
      setshapers(data.shapers);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      caption: "User ID",
      dataField: "userId",
      dataType: "number",
    },
    {
      caption: "User Name",
      dataField: "username",
      dataType: "string",
    },
    {
      caption: "Name",
      dataField: "name",
      dataType: "string",
    },
    {
      caption: "Location Name",
      dataField: "locationName",
      dataType: "string",
    },
    {
      caption: "Profile Picture",
      dataField: "profilePicture",
      dataType: "string",
    },
    {
      caption: "Board Type",
      dataField: "boardTypeName",
      dataType: "number",
    },
    {
      caption: "Board Length",
      dataField: "boardLength",
      dataType: "string",
    },
    {
      caption: "Distance",
      dataField: "distance",
      dataType: "number",
    },

    {
      caption: "Active",
      dataField: "active",
      dataType: "boolean",
    },
    {
      caption: "Verified",
      dataField: "verified",
      dataType: "boolean",
    },
    {
      caption: "Profile Completed",
      dataField: "getStartedCompleted",
      dataType: "boolean",
    },

    {
      caption: "Added on",
      dataField: "dateAdded",
      dataType: "date",
    },
    {
      caption: "Last Login",
      dataField: "lastLogin",
      dataType: "date",
    },
    {
      caption: "Role",
      dataField: "role",
      dataType: "string",
    },
  ];

  const handleEdit = async (usr) => {
    console.log("Editing ", usr);
    setUser(usr);
    setShowForm(true);
  };
  const handleSave = async (updatedUser) => {
    try {
      const { data, message, status } = await trackPromise(
        BaseApiService.POST("/admin/UpdateUser", updatedUser)
      );

      alertify(message, status);
      setShowForm(!status);
      fetchUsers();
    } catch (error) {
      notify(message, error?.message);
    }
  };
  const handleDelete = async (obj) => {
    try {
      const { data, status, message } = await trackPromise(
        BaseApiService.POST(`/admin/DeleteAccountByAdmin/${obj.id}`)
      );

      alertify(message, status);

      fetchUsers();
    } catch (error) {
      notify(error?.message, false);
    }
  };

  const handleCancel = () => {
    setShowForm(false);
  };
  return (
    <>
      <Row>
        <Col className="mr-auto">
          <h2> Registered Users </h2>
        </Col>
        <Col className="mb-2">
          <Space>
            <Checkbox
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  setPrevioustDateRange([...dateRange]);
                  setDateRange([dayjs("2024-01-01"), dayjs()]);
                } else {
                  setDateRange([...previousDateRange]);
                }
                setAllTimesDateRange(checked);
              }}
            />{" "}
            <span className="text-grey">All times registrations!</span>{" "}
            <b>OR</b>
            <label className="text-small">Registered between: </label>
            <RangePicker
              format={dateFormat}
              allowClear={true}
              allowEmpty={[true, true]}
              onCalendarChange={(e) => {
                setDateRange(e);
              }}
              //value={dateRange}
              defaultValue={dateRange}
              //defaultValue={[moment(dateRange.sDate), moment(dateRange.eDate)]}
            />
            <Button onClick={fetchUsers}>Search</Button>
          </Space>
        </Col>
      </Row>
      <CustomDataGrid
        data={users}
        columns={columns}
        PK_FIELD={"userId"}
        ENTITY={"User"}
        ENTITY_PLURAL={"Users"}
        editHandler={handleEdit}
        deleteHandler={handleDelete}
        canEdit={true}
        canDelete={true}
        //doPaging={false}
      />

      <Modal
        width={768}
        //title={"Edit User"}
        open={showForm}
        // onOk={handleSave}
        onCancel={handleCancel}
        footer={null}
        // footer={[<FormButtons {...{ form, handleCancel, ENTITY }} />]}
      >
        {user && (
          <UsersForm
            data={user}
            onSave={handleSave}
            onCancel={handleCancel}
            lookups={{ locations, boardTypes }}
          />
        )}
      </Modal>
    </>
  );
};

export default Users;
