import { Col, Row, Space } from "antd";

import "./privacy-policy.css";
import "../landing-page/Landing.css";

export const PrivacyPolicy = () => {
  return (
    <div style={{ marginLeft: "50px" }}>
      <Row gutter={[30, 30]} className="privacy-policy">
        <Col xs={22} md={22}>
        <h1>Boardsales Pty Ltd</h1>
    <p>ACN: 678 308 426</p>

    <h2>BOARDSALES.COM – PRIVACY POLICY</h2>

    <h3>Personal Information We Collect</h3>
    <p>We may collect the following types of personal information about you:</p>
    <ul>
        <li>Contact Information: Name, email address, mailing address, street address, phone number.</li>
        <li>Images and Media: Photos and videos of you or your surfboards.</li>
        <li>Demographic Information: Age, date of birth, postcode.</li>
        <li>Preferences and Feedback: Your opinions, preferences, and information provided through customer surveys.</li>
        <li>Service Details: Information about products and services we have provided to you, or you have enquired about.</li>
        <li>Technical Information: Browser session data, geo-location data, device and network information, page views, sessions, acquisition sources, search queries, and browsing behaviour.</li>
        <li>Usage Data: Information about your access and use of our site, including through cookies, your communications with our site, the type of browser and operating system you use, and your Internet service provider.</li>
        <li>Other Information: Additional personal information provided by you or third parties through our site, applications, social media platforms, or accounts.</li>
    </ul>
    <p>We may collect this information directly from you or from third parties.</p>

    <h3>Collection and Use of Personal Information</h3>
    <p>We collect, hold, use, and disclose personal information for the following purposes:</p>
    <ul>
        <li>To enable you to access and use our site, applications, and social media platforms.</li>
        <li>To contact and communicate with you.</li>
        <li>For internal record keeping and administrative purposes.</li>
        <li>For analytics, market research, and business development, including to improve our site, applications, and social media platforms.</li>
        <li>To run competitions and offer additional benefits to you.</li>
        <li>For advertising and marketing, including sending promotional information about our products and services and third parties that may interest you.</li>
        <li>To comply with legal obligations and resolve disputes.</li>
    </ul>

    <h3>Disclosure of Personal Information to Third Parties</h3>
    <p>We may disclose personal information to:</p>
    <ul>
        <li>Third-party service providers for services such as IT, data storage, web hosting, server providers, debt collectors, marketing, advertising, professional advisors, and payment systems operators.</li>
        <li>Employees, contractors, and related entities.</li>
        <li>Existing or potential agents or business partners.</li>
        <li>Sponsors or promoters of any competition we run.</li>
        <li>Parties to whom our business or assets may be transferred.</li>
        <li>Courts, tribunals, regulatory authorities, and law enforcement officers as required by law.</li>
        <li>Third parties assisting us in providing information, products, services, or direct marketing to you.</li>
        <li>Third parties to collect and process data, such as Google Analytics.</li>
    </ul>
    <p>We may store personal information overseas. Where we disclose your personal information to third parties overseas, we ensure these parties protect your information in accordance with the Australian Privacy Principles or similar laws.</p>

    <h3>How We Treat Sensitive Information</h3>
    <p>Sensitive information is given a higher level of protection and is collected, held, used, and disclosed only for purposes to which you consent or as required by law.</p>

    <h3>Your Rights and Controlling Your Personal Information</h3>
    <ul>
        <li>Choice and Consent: By providing personal information, you consent to its collection, use, and disclosure in accordance with this policy.</li>
        <li>Information from Third Parties: If we receive your personal information from a third party, we will protect it as set out in this policy.</li>
        <li>Restrict: You may restrict the collection or use of your personal information.</li>
        <li>Access: You may request details of the personal information we hold about you.</li>
        <li>Correction: You may correct any information that is inaccurate, out of date, incomplete, irrelevant, or misleading.</li>
        <li>Complaints: If you believe we have breached the Australian Privacy Principles, please contact us to address your complaint.</li>
        <li>Unsubscribe: To unsubscribe from communications, please contact us or use the opt-out facilities provided.</li>
    </ul>

    <h3>Data Storage and Security</h3>
    <p>We implement security measures to protect your personal information from misuse, interference, loss, unauthorized access, modification, or disclosure. However, we cannot guarantee the security of information transmitted over the Internet.</p>

    <h3>Cookies and Web Beacons</h3>
    <p>We use cookies and web beacons to monitor and collect data about your interactions with our site. This data helps us improve your experience and target advertisements.</p>

    <h3>Links to Other Websites</h3>
    <p>Our site may contain links to other websites not governed by this policy. We are not responsible for the protection and privacy of any information you provide on those websites.</p>

    <h3>Amendments</h3>
    <p>We may amend this privacy policy at any time by publishing the updated policy on our site. We recommend you check our site regularly for updates.</p>

    <p>For any questions or notices, please contact us at:</p>
    <p>
        Boardsales Pty Ltd<br />
        Email: <a href="mailto:boardsalesglobal@gmail.com">boardsalesglobal@gmail.com</a><br />
        Last updated: 1st August 2024
    </p>
        </Col>
      </Row>
    </div>
  );
};
